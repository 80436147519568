import "./polyfills";
import store, { injectReducer } from "./store";
import React, { useEffect, ReactElement, Suspense } from "react";
import ReactDOM from "react-dom";
import { TransitionGroup } from "react-transition-group";
import { Provider } from "react-redux";
import { initIntl } from "common/utils/intl";
import { defaultLocale } from "common/utils/holvikaari";
import { areConversationsEnabled } from "common/utils/general";
import { pageLoadFailed } from "common/utils/api";
import AppWrapper from "common/containers/AppWrapper";
import InputForm from "shared/components/InputForm/InputForm.lazy";
import ModalProvider from "shared/components/Modal";
import TooltipProvider from "common/components/Tooltip";
import GlobalFontsProvider from "common/components/GlobalFontsProvider";
import UserDataTable from "hcp/containers/UserDataTable.lazy";
import { getUser, setLocale } from "common/actions/user";
import { once } from "lodash";
import Tagger from "hcp/components/Staff/Tagger";
import Conversation from "shared/components/Conversation/Conversation.lazy";
import Markdown, { PlainMarkdown } from "common/components/Markdown";
import { epic$ } from "epic";
import WelcomeStaff from "hcp/containers/WelcomeStaff.lazy";
import SearchBar from "common/components/SearchBar";
import { SymptomTableContainer as SymptomTable } from "shared/components/SymptomTable";
import Feedback from "shared/components/Feedback/Feedback.lazy";
import LoginPage from "shared/components/LoginPage/LoginPage.lazy";
import ExternalLoginPageFooter from "shared/components/LoginPage/ExternalLoginPageFooter.lazy";
import InvitePage from "client/components/InvitePage";
import { CompareValues } from "client/components/CompareAnswers";
import CrcModalLink from "shared/components/Modal/CrcModalLink";
import { MobilePsaModalLink } from "shared/components/Modal/MobilePsaModal";
import DownloadView from "shared/components/DownloadView";
import PopulationDashboard from "hcp/containers/PopulationDashboard.lazy";
import Ecsc from "shared/components/Ecsc/Ecsc.lazy";
import MonitoringAndCareTeam from "hcp/containers/MonitoringAndCareTeam.lazy";
import OnlineHelp from "hcp/components/OnlineHelp/OnlineHelp.lazy";
import MonitoringProgramTable from "hcp/containers/MonitoringProgramTable.lazy";
import SelfServiceFlipper from "shared/components/SelfServiceFlipper/SelfServiceFlipper.lazy";
import IxrsApiTestTool from "hcp/components/IxrsApiTestTool/IxrsApiTestTool.lazy";
import PDFExport from "hcp/containers/PDFExport";
import Caregivers from "hcp/containers/Caregivers.lazy";
import { RootStyles } from "./styles/holvikaari.styles";
import SymptomComparisonExport from "hcp/components/PDFExport/SymptomComparisonExport";
import EditProgramView from "hcp/containers/EditProgramView.lazy";
import AriaDescriptionProvider from "common/hooks/UseAriaDescription/AriaDescriptionProvider";
import UserFeedbackBox from "shared/components/UserFeedbackBox";
import LoginAuthentication from "shared/components/LoginAuthentication/LoginAuthentication.lazy";
import MonitoringProgramSelect from "hcp/components/MonitoringProgramSelect/MonitoringProgramSelect.lazy";
import CopyToClipboard from "hcp/components/Clipboard/CopyToClipboard.lazy";
import { getIntegrationTestAxe } from "common/utils/a11y";
import InvitePatients from "hcp/components/Invites/InvitePatients.lazy";
import ClientConversationList from "shared/components/ConversationList/ClientConversationList.lazy";
import MessagingRouter from "shared/components/Messaging/MessagingRouter.lazy";
import ViewImages from "common/components/ViewImages";
import AccountStatus from "hcp/containers/AccountStatus.lazy";
import PatientRecords from "hcp/components/PatientRecords/PatientRecords.lazy";
import DocumentsSharedByCareTeam from "hcp/containers/ReceivedDocuments";
import DocumentsSharedByClient from "hcp/containers/SentDocuments";
import CalendarEntries from "hcp/containers/CalendarEntries";
import CreateUser from "hcp/components/Users/CreateUser.lazy";
import UpdateUser from "hcp/components/Users/UpdateUser.lazy";
import { Spinner } from "@netmedi/frontend-design-system";
import TeamsRouter from "hcp/containers/TeamsRouter.lazy";
import NavBarWrapper from "hcp/components/NavBar";
import SinglePatientExport from "hcp/components/SinglePatientExport/SinglePatientExport";
import Absences from "hcp/components/Absences/Absences";
import PatientDashboardBottomLeft from "hcp/components/PatientDashboard/BottomLeft.lazy";

const mountProvider = (component: ReactElement) => {
  const root = document.createElement("div");
  document.body.insertBefore(root, document.body.firstChild);
  ReactDOM.render(component, root);
};

// Called once when first Serena component is mounted
const init = once((loggedOut: boolean) => {
  mountProvider(<Serena view="ModalProvider" noSpinner />);
  mountProvider(<Serena view="TooltipProvider" noSpinner />);
  mountProvider(<Serena view="GlobalFontsProvider" noSpinner />);

  initIntl();
  if (loggedOut) {
    store.dispatch(setLocale(defaultLocale));
  } else {
    getUser()
      .then(action => store.dispatch(action))
      .catch(pageLoadFailed);
  }
  store.dispatch({ type: "SET_HOLVIKAARI" });
});

const initView = (view: string) => {
  switch (view) {
    case "Conversation":
      epic$.next(require("shared/epics/chat").default);
      break;
  }
};

const components = {
  InputForm,
  ModalProvider,
  TooltipProvider,
  GlobalFontsProvider,
  Tagger,
  Conversation,
  UserDataTable,
  ClientConversationList,
  MessagingRouter,
  WelcomeStaff,
  SearchBar,
  SymptomTable,
  LoginPage,
  ExternalLoginPageFooter,
  Feedback,
  CompareValues,
  CrcModalLink,
  MobilePsaModalLink,
  PopulationDashboard,
  Ecsc,
  MonitoringAndCareTeam,
  OnlineHelp,
  MonitoringProgramTable,
  IxrsApiTestTool,
  DownloadView,
  SelfServiceFlipper,
  InvitePage,
  PDFExport,
  SymptomComparisonExport,
  Caregivers,
  EditProgramView,
  UserFeedbackBox,
  LoginAuthentication,
  CopyToClipboard,
  InvitePatients,
  ViewImages,
  MonitoringProgramSelect,
  AccountStatus,
  PatientRecords,
  DocumentsSharedByCareTeam,
  DocumentsSharedByClient,
  CalendarEntries,
  CreateUser,
  UpdateUser,
  TeamsRouter,
  SinglePatientExport,
  Absences,
  PatientDashboardBottomLeft,
} as { [viewId: string]: any };

const loggedOutComponents = [
  "LoginPage",
  "ExternalLoginPageFooter",
  "InvitePage",
  "DownloadView",
  "LoginAuthentication",
];

type SerenaProps = {
  view: any;
  noSpinner?: boolean;
  noPadding?: boolean;
};

function Serena(props: SerenaProps) {
  useEffect(() => {
    init(loggedOutComponents.indexOf(props.view) !== -1);
    initView(props.view);
  }, [props.view]);

  const { view, noSpinner, noPadding, ...rest } = props;
  const Component = components[view];
  return (
    <Provider store={store}>
      <AppWrapper {...{ noSpinner, noPadding }}>
        <RootStyles className="serena-wrap">
          <AriaDescriptionProvider>
            <Suspense fallback={<Spinner />}>
              <Component holvikaari {...(rest as any)} />
            </Suspense>
          </AriaDescriptionProvider>
        </RootStyles>
      </AppWrapper>
    </Provider>
  );
}

// site-specific features
if (areConversationsEnabled()) {
  injectReducer("chat", require("shared/reducers/chat").default);
}

window.React = React;
(window.React as any).addons = { CSSTransitionGroup: TransitionGroup };
window.ReactDOM = ReactDOM;
window.Serena = Serena;
window.NavBarWrapper = NavBarWrapper;
window.LoginPage = LoginPage;
window.InvitePage = InvitePage;
window.Markdown = Markdown;
window.PlainMarkdown = PlainMarkdown;

if (process.env.TEST) {
  window.axe = getIntegrationTestAxe();
}
